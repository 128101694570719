<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="info">
        <img src="@/assets/ps.png" />
        <div class="detail">
          <div class="name">{{name}}</div>
          <div class="tel">{{tel}}</div>
        </div>
      </div>
      <div class="list">
        <div class="listitem" @click="onRecord">
          <img src="@/assets/gyjl.png" class="icon" />
          <div class="text">购药记录</div>
          <img src="@/assets/arrow.png" class="arrow" />
        </div>
      </div>
      <div class="list list2">
        <div class="listitem" @click="onAppraisal">
          <img src="@/assets/jianding.png" class="icon" />
          <div class="text">查看鉴定书</div>
          <img src="@/assets/arrow.png" class="arrow" />
        </div>
      </div>
      <div class="list list2">
        <div class="listitem" @click="onPrescription">
          <img src="@/assets/chufang.png" class="icon" />
          <div class="text">查看慢性病处方</div>
          <img src="@/assets/arrow.png" class="arrow" />
        </div>
      </div>          
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  data() {
    return {
      data: {
        // 1-管理员 2-负责人 3-游客
        site_code: '测试场所',
        user_type: '大厨',
        nick_name: '李某某',
        mobile: '13359665321'
      },
      typeflag:false,
      name: '',
      tel: '',
      unionid: '',
      openid: '',
      hrefurl:''
    };
  },
  methods: {
    
    async onRecord() {
      Toast('购药记录功能尚未开放'); 
      //this.$router.replace({path:'/refueling/ewm_detail', query:{clientcode:this.clientcode,site_code:this.site_code}});         
    },
    async onAppraisal() {
      Toast('查看鉴定书功能尚未开放'); 
      //this.$router.replace({path:'/refueling/bind_post', query:{}}); 
    },
    async onPrescription() {
      Toast('查看慢性病处方功能尚未开放'); 
      //this.$router.replace({path:'/refueling/bind_post', query:{}}); 
    }
  },
  async created() { 
    
    this.name = this.$route.query.name;
    this.tel = this.$route.query.tel;
    this.unionid = this.$route.query.unionid;
    let openid = this.$route.query.openid;
    if (openid !== null && openid !== undefined && openid.length !== 0) {
      window.sessionStorage.setItem('openid', openid); 
    }   

  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 330px;
}
.content {
  margin-top: 60px;
}
.list {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  &.list2 {
    margin-top: 20px;
  }
  .listitem {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    .icon {
      width: 64px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }
    .arrow {
      float: right;
      display: block;
      margin-top: 32px;
      margin-right: 38px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 20px;
      line-height: 44px;
      margin-top: 30px;
    }
    .right {
      line-height: 40px;
      color: #666666;
      font-size: 28px;
      float: right;
      margin-top: 32px;
      margin-right: 50px;
    }
  }
}
.info {
  overflow: hidden;
  margin: 90px 54px 62px;
  img {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    display: block;
    float: left;
  }
  .detail {
    overflow: hidden;
    margin-left: 46px;
    float: left;
    .name {
      margin-top: 20px;
      line-height: 46px;
      color: #fff;
      font-size: 32px;
    }
    .tel {
      height: 40px;
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.6;
    }
  }
}
</style>